import { useEffect } from 'react';
import { useCookie } from 'react-use';

export const useShowTranslationKeys = () => {
  const [showTranslationKeysCookie, updateShowTranslationKeys] = useCookie(
    'ns-show-translation-keys'
  );

  useEffect(() => {
    if (
      window.location.hash === '#ns-labels-enabled' &&
      (showTranslationKeysCookie === 'true' || !showTranslationKeysCookie)
    ) {
      updateShowTranslationKeys('false');
      window.location.reload();
    } else if (
      window.location.hash === '#ns-labels-disabled' &&
      (showTranslationKeysCookie === 'false' || !showTranslationKeysCookie)
    ) {
      updateShowTranslationKeys('true');
      window.location.reload();
    }
  }, [showTranslationKeysCookie, updateShowTranslationKeys]);
};
