import '@/core/utils/wdyr';
import '../public/styles/styles.css';
import './index.css';
import 'leaflet/dist/leaflet.css';

import { type EmotionCache, CacheProvider } from '@emotion/react';
import { initSnowplowTracker } from '@ev/snowplow-library';
import { GlobalStyles } from '@ev/ui-core-components';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleTagManager } from '@next/third-parties/google';
import { SiteTrackingPlugin } from '@snowplow/browser-plugin-site-tracking';
import { SpeedInsights } from '@vercel/speed-insights/next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { type SpearHeadNavigation } from 'spearhead/utils/storyblok/api';

import { muiCustomTheme } from '@/components/common/muiTheme';
import { BlockedLocalStorageDialog } from '@/components/views/BlockedLocalStorage/BlockedLocalStorageDialog';
import { didomiScript } from '@/core/const/scripts';
import { useShowTranslationKeys } from '@/core/hooks/useShowTranslationKeys';
import { BreakpointProvider } from '@/core/providers/BreakpointProvider';
import { SettingsProvider } from '@/core/providers/SettingsProvider';
import { SnackbarProvider } from '@/core/providers/SnackbarProvider';
import { WatchlistProvider } from '@/core/providers/WatchlistProvider';
import { type ABTestVariation } from '@/core/types/tracking.types';
import { setLanguageCookie } from '@/core/utils/cookieUtils';
import createEmotionCache from '@/core/utils/createEmotionCache';
import {
  attachConsentListeners,
  hasUserAcceptedTracking,
} from '@/core/utils/didomiCMPUtils';
import { isLocalStorageAvailable } from '@/core/utils/localStorage';
import { getLicencePartnerGAAccount } from '@/core/utils/tracking/tracking';
import { type ExternalLinkDomain } from '@/lib';

import nextI18NextConfig from '../next-i18next.config.js';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export type CommonPageProps = {
  pageLanguages: string[];
  isSnowplowTrackerInit?: boolean;
  spearHeadNavigation?: SpearHeadNavigation | null;
  externalLinkDomains?: ExternalLinkDomain[] | null;
  abTestVariation?: ABTestVariation;
};

const SearchApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [isSnowplowTrackerInit, setIsSnowplowTrackerInit] = useState(false);
  const [hasLicencePartnerGAAccount, setHasLicencePartnerGAAccount] =
    useState(false);

  const {
    i18n: { language },
  } = useTranslation();
  const [isBlockedLocalStorageDialogOpen, setIsBlockedLocalStorageDialogOpen] =
    useState(!isLocalStorageAvailable());
  useShowTranslationKeys();

  useEffect(() => {
    if (!isSnowplowTrackerInit) {
      initSnowplowTracker({
        applicationId: 'new-search',
        hasUserAcceptedAgreement: hasUserAcceptedTracking(),
        isProd: window.location.host.includes('engelvoelkers.com'),
        initialTrackPageViewEvent: false,
        plugins: [SiteTrackingPlugin()],
      });
      setIsSnowplowTrackerInit(true);
    }
    attachConsentListeners();
    const licencePartnerGaAccount = getLicencePartnerGAAccount();
    if (licencePartnerGaAccount) {
      setHasLicencePartnerGAAccount(true);
      window.dataLayer?.push({
        event: 'licencePartnerGAAccount',
        licencePartnerGA: licencePartnerGaAccount,
      });
    }
    setLanguageCookie(language);
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Search</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1"
        />
        {/* it resolves a hydration issue on ios https://github.com/vercel/next.js/issues/38290 */}
        <meta name="format-detection" content="telephone=no" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://uploadcare.engelvoelkers.com" />
        <link rel="preconnect" href="https://fonts.engelvoelkers.com" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://uploadcare.engelvoelkers.com/20367ee3-b0ec-4b47-a7a4-a9f6fe0f23e9/"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://uploadcare.engelvoelkers.com/11642d33-556d-40ea-91fd-bb8e9a93f200/"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://uploadcare.engelvoelkers.com/4af56219-669f-4b35-9760-d11efef10cb7/"
        />
        <link
          rel="icon"
          href="https://uploadcare.engelvoelkers.com/9b1114be-3ea4-4421-8130-4d6ce3035728/"
        />
        <link
          rel="shortcut icon"
          href="https://uploadcare.engelvoelkers.com/9b1114be-3ea4-4421-8130-4d6ce3035728/"
        />
        <link
          rel="preload"
          href="https://fonts.engelvoelkers.com/EngelVoelkersHead_W_Wght.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="https://fonts.engelvoelkers.com/EngelVoelkersText_W_Wght.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>
      {!process.env.NEXT_PUBLIC_E2E_ENV && (
        <>
          <GoogleTagManager
            gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!}
          />
          <Script
            strategy="afterInteractive"
            id="cmp-script"
            dangerouslySetInnerHTML={{
              __html: didomiScript,
            }}
          />
        </>
      )}
      <SpeedInsights
        sampleRate={0.2}
        scriptSrc="https://new-search.engelvoelkers.com/_vercel/speed-insights/script.js"
        endpoint="https://new-search.engelvoelkers.com/_vercel/speed-insights/vitals"
      />
      <SettingsProvider>
        <ThemeProvider theme={muiCustomTheme}>
          <GlobalStyles />
          <SnackbarProvider>
            <WatchlistProvider>
              <BreakpointProvider>
                <CssBaseline />
                {isBlockedLocalStorageDialogOpen ? (
                  <BlockedLocalStorageDialog
                    onClose={() => setIsBlockedLocalStorageDialogOpen(false)}
                  />
                ) : null}
                <Component
                  isSnowplowTrackerInit={isSnowplowTrackerInit}
                  hasLicencePartnerGAAccount={hasLicencePartnerGAAccount}
                  {...pageProps}
                />
              </BreakpointProvider>
            </WatchlistProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </SettingsProvider>
    </CacheProvider>
  );
};

export default appWithTranslation(SearchApp, nextI18NextConfig);
